import { shape, string, number, instanceOf } from 'prop-types';

export const PrestoCluster = shape({
  status: string.isRequired,
  name: string.isRequired,
  clusterConfiguration: shape({
    masterNodes: number,
    workerNodes: number,
  }),
  statusDate: instanceOf(Date).isRequired,
  prestoConfiguration: shape({
    prestoClusterEndpoint: string,
  }),
});

export const Credential = shape({
  type: string.isRequired,
  username: string.isRequired,
  password: string.isRequired,
});

export const AutoScalingPolicyIdleCostSavings = shape({
  idleTime: number.isRequired,
  workerNodes: number.isRequired,
  type: string.isRequired,
});

export const AutoScalingPolicyCPU = shape({
  idleTime: number.isRequired,
  minWorkerNodes: number.isRequired,
  maxWorkerNodes: number.isRequired,
  stepSize: number.isRequired,
  type: string.isRequired,
});

export const DownloadLinks = shape({
  prestoCLI: string,
  prestoJDBC: string,
});
