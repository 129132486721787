import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { bool, oneOfType, string, shape, number } from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Banner from '../../Banner';
import Form from '../../Form';
import Page from '../../Page';
import Help from '../../Help';
import PrestoClusters from '../../PrestoClusters';
import { AutoScaling } from '../../PrestoClusterAdd/components';
import * as constants from '../../PrestoClusterAdd/constants';
import { ActionInProgressStateContext } from '../../utils/ActionInProgressContext';
import PendingStatus from './PendingStatus';
import PrestoClusterInfo from './PrestoClusterInfo';
import PageHelpIdleCostSavings from './PageHelpIdleCostSavings';
import PageHelpCPUScaleIn from './PageHelpCPUScaleIn';

const display = {
  header: 'Change Scaling Policy',
  clusterAutoScalingHeader: 'Cluster Scaling',
  formContentHeader: 'Scaling Details',
  cancelButton: 'Cancel',
  saveButton: 'Modify Cluster',
  savingButton: 'Saving...',
  confirmCancel:
    'Proceeding with the action will result in the loss of all changes. Would you like to proceed?',
  confirmTitle:
    'This action will immediately remove worker nodes from this Presto cluster.',
  confirmDetails: 'Any queries already running on the cluster might fail.',
};

const ChangeAutoScalingPolicyForm = ({
  prestoClusterId,
  prestoCluster,
  actionStatus,
  maxWorkerNodeCount,
  usePop,
  submitSuccess,
  status,
  name,
  autoScalingType,
  autoScalingPolicy,
}) => {
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const actionInProgress = useContext(ActionInProgressStateContext);

  const submitInProgress = useMemo(() => {
    return (
      actionStatus &&
      actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    );
  }, [actionStatus, actionInProgress]);

  useEffect(() => {
    if (
      actionStatus &&
      !actionStatus.inProgress &&
      actionStatus.actionId === actionInProgress
    ) {
      setConfirmSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  const onConfirm = useCallback(() => {
    setConfirmSubmit(true);
  }, []);

  const cancelConfirm = useCallback(() => {
    setConfirmSubmit(false);
  }, []);

  const getRoute = useCallback(() => {
    return PrestoClusters.routes.getManagePrestoClusterRoute(prestoClusterId);
  }, [prestoClusterId]);

  const pageHelp = useMemo(() => {
    if (autoScalingType === constants.autoScaling.cpu) {
      return PageHelpCPUScaleIn.PageHelp;
    }
    return PageHelpIdleCostSavings;
  }, [autoScalingType]);

  const { watch } = useFormContext();
  const watchWorkerNodes = watch('autoScaling.workerNodes');
  const watchMaxWorkerNodes = watch('autoScaling.maxWorkerNodes');

  const showConfirmation = useMemo(() => {
    const currentWorkerNodes = prestoCluster.clusterConfiguration.workerNodes;
    if (autoScalingType === constants.autoScaling.cpu) {
      return watchMaxWorkerNodes < currentWorkerNodes;
    }
    if (autoScalingType === constants.autoScaling.idleCostSavings) {
      return watchWorkerNodes < currentWorkerNodes;
    }
    return false;
  }, [watchMaxWorkerNodes, watchWorkerNodes, autoScalingType, prestoCluster]);

  const { onCancel } = Form.useFormNavigation({
    submitSuccess,
    confirmCancelMessage: display.confirmCancel,
    getRoute,
    usePop,
  });

  const canChangeAutoScalingPolicy =
    (status === PrestoClusters.constants.status.active ||
      status === PrestoClusters.constants.status.inactive) &&
    !prestoCluster.workflowInProgress;

  const showActionStatusError =
    actionStatus &&
    actionStatus.error &&
    !actionStatus.inProgress &&
    actionStatus.name === 'editAutoScalingPolicy';

  return (
    <Help.HelpNavigation>
      <Page.PageHeader>
        <h1>{display.header}</h1>
        <div className='buttons'>
          <Form.SecondaryButton
            onClick={onCancel}
            label={display.cancelButton}
            disabled={submitInProgress}
            disabledOnErrors={false}
          />
        </div>
      </Page.PageHeader>
      <Page.FormPage>
        <Page.FormColumn>
          {!canChangeAutoScalingPolicy && (
            <Banner scrollIntoView>
              <PendingStatus name={name} status={status} />
            </Banner>
          )}
          {showActionStatusError && (
            <Banner scrollIntoView title={actionStatus.error} />
          )}
          <PrestoClusterInfo name={name} />
          <Page.Box>
            <Page.BoxHeader>
              <h2>{display.clusterAutoScalingHeader}</h2>
            </Page.BoxHeader>
            <Page.FormContent rowCount={2}>
              <div>
                <h3>{display.formContentHeader}</h3>
              </div>
              <AutoScaling
                autoScalingType={autoScalingType}
                defaultAutoScaling={autoScalingPolicy}
                disabled={confirmSubmit || submitInProgress}
                maxWorkerNodeCount={maxWorkerNodeCount}
              />
            </Page.FormContent>
          </Page.Box>
          {!submitInProgress && confirmSubmit && (
            <Banner
              title={display.confirmTitle}
              details={display.confirmDetails}
            >
              <div className='buttons'>
                <Form.SecondaryButton
                  onClick={cancelConfirm}
                  label={display.cancelButton}
                  disabledOnErrors={false}
                />
                <Form.Submit label={display.saveButton} />
              </div>
            </Banner>
          )}
          {(submitInProgress || !confirmSubmit) && (
            <Page.Buttons>
              <Form.SecondaryButton
                onClick={onCancel}
                label={display.cancelButton}
                disabled={submitInProgress}
                disabledOnErrors={false}
              />
              {!showConfirmation ? (
                <Form.Submit
                  label={
                    !submitInProgress
                      ? display.saveButton
                      : display.savingButton
                  }
                  disabled={submitInProgress || !canChangeAutoScalingPolicy}
                  showLoading={submitInProgress}
                />
              ) : (
                <Form.PrimaryButton
                  onClick={onConfirm}
                  label={
                    !submitInProgress
                      ? display.saveButton
                      : display.savingButton
                  }
                  disabled={submitInProgress || !canChangeAutoScalingPolicy}
                  showLoading={submitInProgress}
                />
              )}
            </Page.Buttons>
          )}
        </Page.FormColumn>
        <Help.HelpColumn DefaultHelp={pageHelp} />
      </Page.FormPage>
    </Help.HelpNavigation>
  );
};

ChangeAutoScalingPolicyForm.defaultProps = {
  prestoCluster: null,
  prestoClusterId: null,
  actionStatus: undefined,
  usePop: false,
  submitSuccess: false,
};

ChangeAutoScalingPolicyForm.propTypes = {
  name: string.isRequired,
  prestoCluster: PrestoClusters.propTypes.PrestoCluster,
  prestoClusterId: string,
  status: string.isRequired,
  actionStatus: shape({
    actionId: number.isRequired,
    success: bool.isRequired,
    inProgress: bool.isRequired,
    error: string,
  }),
  autoScalingType: string.isRequired,
  autoScalingPolicy: oneOfType([
    PrestoClusters.propTypes.AutoScalingPolicyIdleCostSavings,
    PrestoClusters.propTypes.AutoScalingPolicyCPU,
  ]).isRequired,
  maxWorkerNodeCount: number.isRequired,
  usePop: bool,
  submitSuccess: bool,
};

export default ChangeAutoScalingPolicyForm;
