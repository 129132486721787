export const maxWorkerNodes = 100;
export const autoScaling = Object.freeze({
  idleCostSavings: 'idleCostSavings',
  cpu: 'cpu',
});

const generateInstanceTypeList = (
  skipGraviton = false,
  coordinatorInstanceTypes = false,
) => {
  // IMPORTANT READ THIS:
  // if you add instance types make sure to add pricing to the instance
  // in the billingray database
  // also, make sure to also specify an order for in the sorting function below for any new
  // instance families and/or instance types
  const instanceTypes = [
    { display: 'r5.large', value: 'r5.large' },
    { display: 'r5.xlarge', value: 'r5.xlarge' },
    { display: 'r5.2xlarge', value: 'r5.2xlarge' },
    { display: 'r5.4xlarge', value: 'r5.4xlarge' },
    { display: 'r5.8xlarge', value: 'r5.8xlarge' },
    { display: 'r5.12xlarge', value: 'r5.12xlarge' },
    { display: 'r5.16xlarge', value: 'r5.16xlarge' },
    { display: 'r5.24xlarge', value: 'r5.24xlarge' },
    { display: 'r5a.large', value: 'r5a.large' },
    { display: 'r5a.xlarge', value: 'r5a.xlarge' },
    { display: 'r5a.2xlarge', value: 'r5a.2xlarge' },
    { display: 'r5a.4xlarge', value: 'r5a.4xlarge' },
    { display: 'r5a.8xlarge', value: 'r5a.8xlarge' },
    { display: 'r5a.12xlarge', value: 'r5a.12xlarge' },
    { display: 'r5a.16xlarge', value: 'r5a.16xlarge' },
    { display: 'r5a.24xlarge', value: 'r5a.24xlarge' },
    { display: 'r5d.4xlarge', value: 'r5d.4xlarge' },
    { display: 'r5d.8xlarge', value: 'r5d.8xlarge' },
    { display: 'r5d.12xlarge', value: 'r5d.12xlarge' },
    { display: 'm5.large', value: 'm5.large' },
    { display: 'm5.xlarge', value: 'm5.xlarge' },
    { display: 'm5.2xlarge', value: 'm5.2xlarge' },
    { display: 'm5.4xlarge', value: 'm5.4xlarge' },
    { display: 'm5.8xlarge', value: 'm5.8xlarge' },
    { display: 'm5.12xlarge', value: 'm5.12xlarge' },
    { display: 'm5.16xlarge', value: 'm5.16xlarge' },
    { display: 'm5a.large', value: 'm5a.large' },
    { display: 'm5a.xlarge', value: 'm5a.xlarge' },
    { display: 'm5a.2xlarge', value: 'm5a.2xlarge' },
    { display: 'm5a.4xlarge', value: 'm5a.4xlarge' },
    { display: 'm5a.8xlarge', value: 'm5a.8xlarge' },
    { display: 'm5a.12xlarge', value: 'm5a.12xlarge' },
    { display: 'm5a.16xlarge', value: 'm5a.16xlarge' },
    { display: 'm5a.24xlarge', value: 'm5a.24xlarge' },
    { display: 'm5d.4xlarge', value: 'm5d.4xlarge' },
    { display: 'm5d.8xlarge', value: 'm5d.8xlarge' },
    { display: 'm5d.12xlarge', value: 'm5d.12xlarge' },
    { display: 'm5d.16xlarge', value: 'm5d.16xlarge' },
    { display: 'm5d.24xlarge', value: 'm5d.24xlarge' },
    { display: 'm6i.large', value: 'm6i.large' },
    { display: 'm6i.xlarge', value: 'm6i.xlarge' },
    { display: 'm6i.2xlarge', value: 'm6i.2xlarge' },
    { display: 'm6i.4xlarge', value: 'm6i.4xlarge' },
    { display: 'm6i.8xlarge', value: 'm6i.8xlarge' },
    { display: 'm6i.12xlarge', value: 'm6i.12xlarge' },
    { display: 'm6i.16xlarge', value: 'm6i.16xlarge' },
    { display: 'm6i.24xlarge', value: 'm6i.24xlarge' },
    { display: 'm6i.32xlarge', value: 'm6i.32xlarge' },
    { display: 'c5.xlarge', value: 'c5.xlarge' },
    { display: 'c5.2xlarge', value: 'c5.2xlarge' },
    { display: 'c5.4xlarge', value: 'c5.4xlarge' },
    { display: 'c5.9xlarge', value: 'c5.9xlarge' },
    { display: 'c5.12xlarge', value: 'c5.12xlarge' },
    { display: 'c5.18xlarge', value: 'c5.18xlarge' },
    { display: 'c5a.xlarge', value: 'c5a.xlarge' },
    { display: 'c5a.2xlarge', value: 'c5a.2xlarge' },
    { display: 'c5a.4xlarge', value: 'c5a.4xlarge' },
    { display: 'c5a.8xlarge', value: 'c5a.8xlarge' },
    { display: 'c5a.12xlarge', value: 'c5a.12xlarge' },
    { display: 'c5a.16xlarge', value: 'c5a.16xlarge' },
    { display: 'c5a.24xlarge', value: 'c5a.24xlarge' },
    { display: 'c5d.xlarge', value: 'c5d.xlarge' },
    { display: 'c5d.2xlarge', value: 'c5d.2xlarge' },
    { display: 'c5d.4xlarge', value: 'c5d.4xlarge' },
    { display: 'c5d.9xlarge', value: 'c5d.9xlarge' },
    { display: 'c5d.12xlarge', value: 'c5d.12xlarge' },
    { display: 'm4.large', value: 'm4.large' },
    { display: 'm4.xlarge', value: 'm4.xlarge' },
    { display: 'm4.2xlarge', value: 'm4.2xlarge' },
    { display: 'm4.4xlarge', value: 'm4.4xlarge' },
    { display: 'm4.10xlarge', value: 'm4.10xlarge' },
  ];

  if (!coordinatorInstanceTypes) {
    instanceTypes.push({ display: 'c5.large', value: 'c5.large' });
    instanceTypes.push({ display: 'c5a.large', value: 'c5a.large' });
    instanceTypes.push({ display: 'c5d.large', value: 'c5d.large' });
  }

  if (!skipGraviton) {
    instanceTypes.push({ display: 'r6g.xlarge', value: 'r6g.xlarge' });
    instanceTypes.push({ display: 'r6g.2xlarge', value: 'r6g.2xlarge' });
    instanceTypes.push({ display: 'r6g.4xlarge', value: 'r6g.4xlarge' });
    instanceTypes.push({ display: 'r6g.8xlarge', value: 'r6g.8xlarge' });
    instanceTypes.push({ display: 'r6g.12xlarge', value: 'r6g.12xlarge' });
    instanceTypes.push({ display: 'r6g.16xlarge', value: 'r6g.16xlarge' });

    instanceTypes.push({ display: 'm6g.xlarge', value: 'm6g.xlarge' });
    instanceTypes.push({ display: 'm6g.2xlarge', value: 'm6g.2xlarge' });
    instanceTypes.push({ display: 'm6g.4xlarge', value: 'm6g.4xlarge' });
    instanceTypes.push({ display: 'm6g.8xlarge', value: 'm6g.8xlarge' });
    instanceTypes.push({ display: 'm6g.12xlarge', value: 'm6g.12xlarge' });
    instanceTypes.push({ display: 'm6g.16xlarge', value: 'm6g.16xlarge' });

    instanceTypes.push({ display: 'c6g.xlarge', value: 'c6g.xlarge' });
    instanceTypes.push({ display: 'c6g.2xlarge', value: 'c6g.2xlarge' });
    instanceTypes.push({ display: 'c6g.4xlarge', value: 'c6g.4xlarge' });
    instanceTypes.push({ display: 'c6g.8xlarge', value: 'c6g.8xlarge' });
    instanceTypes.push({ display: 'c6g.12xlarge', value: 'c6g.12xlarge' });
    instanceTypes.push({ display: 'c6g.16xlarge', value: 'c6g.16xlarge' });

    instanceTypes.push({ display: 'i3.xlarge', value: 'i3.xlarge' });
    instanceTypes.push({ display: 'i3.2xlarge', value: 'i3.2xlarge' });
    instanceTypes.push({ display: 'i3.4xlarge', value: 'i3.4xlarge' });
    instanceTypes.push({ display: 'i3.8xlarge', value: 'i3.8xlarge' });
    instanceTypes.push({ display: 'i3.16xlarge', value: 'i3.16xlarge' });
  }

  const instanceFamilyOrder = [
    'r5',
    'r5a',
    'r5d',
    'r6g',
    'm5',
    'm5a',
    'm5d',
    'm6i',
    'm6g',
    'c5',
    'c5a',
    'c5d',
    'c6g',
    'm4',
    'i3',
  ];
  const instanceTypeOrder = [
    'large',
    'xlarge',
    '2xlarge',
    '4xlarge',
    '8xlarge',
    '9xlarge',
    '10xlarge',
    '12xlarge',
    '16xlarge',
    '18xlarge',
    '24xlarge',
    '32xlarge',
  ];

  // Multi-level sorting: first by instance family and then by instance type
  return instanceTypes.sort((a, b) => {
    const [instanceFamilyA, instanceTypeA] = a.display.split('.');
    const [instanceFamilyB, instanceTypeB] = b.display.split('.');

    if (instanceFamilyA !== instanceFamilyB) {
      return (
        instanceFamilyOrder.indexOf(instanceFamilyA) -
        instanceFamilyOrder.indexOf(instanceFamilyB)
      );
    }

    return (
      instanceTypeOrder.indexOf(instanceTypeA) -
      instanceTypeOrder.indexOf(instanceTypeB)
    );
  });
};

export const communityEditionInstanceTypes = generateInstanceTypeList(true);
export const instanceTypeValues = generateInstanceTypeList();
export const communityEditionCoordinatorInstanceTypes =
  generateInstanceTypeList(true, true);
export const coordinatorInstanceTypeValues = generateInstanceTypeList(
  false,
  true,
);
export const hmsInstanceTypeValues = generateInstanceTypeList(true);
